import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { format as Tformat } from 'o365.lib.date-fns.js';
import type { isDateExpression as TisDateExpression, getDateExpression as TgetDateExpression, dateExpressionValues as TdateExpressionValues } from 'o365.pwa.modules.sw.dateExpressions.d.ts';

import type { OperatorTitles as ToperatorTitles } from 'o365.pwa.modules.sw.filter.constants.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

export type Group = 'group';
export type Expression = 'expression';
export type TYPE = Group | Expression;

export type And = 'and';
export type Or = 'or';
export type MODE = And | Or;

export interface FilterItem {
    value: any,
    valueType: string,
    operator: string,
    column: string,
    type: Expression
}

export interface FilterGroup {
    items: Array<FilterItem>,
    mode: MODE,
    type: Group
    //  group:MODE
}

export interface FilterObject {
    items: Array<FilterGroup>;
    mode: MODE;
    type: TYPE;
}

(() => {

    const { isDateExpression, getDateExpression, dateExpressionValues } = self.o365.importScripts<{
        isDateExpression: typeof TisDateExpression;
        getDateExpression: typeof TgetDateExpression;
        dateExpressionValues: typeof TdateExpressionValues;
    }>("o365.pwa.modules.sw.dateExpressions.ts");

    const operatorTitles = self.o365.importScripts<{ operatorTitles: ToperatorTitles }>("o365.pwa.modules.sw.filter.constants.ts");

    const { formatDate } = self.o365.importScripts<{ formatDate: typeof Tformat }>("o365.pwa.libs.date-fns.ts");

    const reverse_string = function (pString: string) {
        var s = "";
        var i = pString.length;
        while (i > 0) {
            s += pString.substring(i - 1, i);
            i--;
        }
        return s;
    }

    class Devex {
        static parseValueFromServer(pValue: any, pType: string) {
            if (pValue && pValue.constructor === Object) {
                if (pValue.hasOwnProperty("first") && pValue.hasOwnProperty("second")) {
                    if (pType === "date" || pType === "datetime") {
                        return [dateParser(pValue.first), dateParser(pValue.second)];
                    } else if (pType === "number") {
                        return [parseFloat(pValue.first), parseFloat(pValue.second)];
                    }
                    return [pValue.first, pValue.second];
                }
            }
            return pValue;
        }
        static toSQL(pFilter: FilterObject) { return sqlify(pFilter); };
        static toPrettyString = toPrettyString;
        static prettifyExpression = prettifyExpression;
        static sqlifyExpression = sqlifyExpression;

        static toJSON(pFilter: FilterObject) {
            return JSON.stringify(pFilter);
        };

        static filter(pFilter: any, pArray: Array<any>) { return pArray.slice(0).filter(function (item) { return evaluate(pFilter, item); }); };

    }

    const regexes = {
        beginBracket: /\[/g,
        apostrophe: /'/g,
        isodate: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?Z$/,
        jsondate: /^\/Date\((-?[0-9]+)\)\/$/i,
        uniqueidentifier: /^[a-z0-9]{8}-([a-z0-9]{4}-){3}[a-z0-9]{12}$/i,
        regexEscaper: /(\/|\.|\*|\+|\?|\||\(|\)|\[|\]|\{|\}|\\)/g
    };

    function dateParser(val: any) {
        let vTmp: any;
        if (typeOf(val) === "datetime") { return val; }
        if (typeOf(val) === "string") {
            if (!val.indexOf("/Date(")) { return new Date(Number(val.substring(6, val.length - 2))); }
            if (regexes.isodate.test(val)) { return new Date(val); }
            if (isDateExpression(val)) {
                return getDateExpression(val);
            }
            vTmp = new Date(val);


            if (!isNaN(vTmp.valueOf())) { return vTmp; }
        }
        return null; // If all date checks fail, return null
    };

    const operatorEvaluators = {
        equals: function (pFieldValue: any, pTestingValue: any) { return pFieldValue === pTestingValue; },
        greaterthan: function (pFieldValue: any, pTestingValue: any) { return pFieldValue > pTestingValue; },
        lessthan: function (pFieldValue: any, pTestingValue: any) { return pFieldValue < pTestingValue; },
        beginswith: function (pFieldValue: any, pTestingValue: any) { return (pFieldValue && pTestingValue ? !pFieldValue.indexOf(pTestingValue) : false); },
        endswith: function (pFieldValue: string, pTestingValue: string) { return (pFieldValue && pTestingValue ? !reverse_string(pFieldValue).indexOf(reverse_string(pTestingValue)) : false); },
        contains: function (pFieldValue: string, pTestingValue: string) { return operatorEvaluators.like(pFieldValue, "%" + pTestingValue + "%"); },
        isnull: function (pFieldValue: any) { return pFieldValue === null; },
        istrue: function (pFieldValue: any) { return pFieldValue === true; },
        inlist: function (pFieldValue: any, pTestingValues: any) { return (pTestingValues instanceof Array ? !!pTestingValues.filter(function (item) { return item === pFieldValue; }).length : false); },
        between: function (pFieldValue: any, pTestingValues: any) { return (pTestingValues instanceof Array ? pFieldValue >= pTestingValues[0] && pFieldValue <= pTestingValues[1] : false); },
        like: function (pFieldValue: string, pTestingValue: string) {
            var vRegEx = pTestingValue.replace(regexes.regexEscaper, "\\$1"); // Escape all chars that need regex escaping
            vRegEx = vRegEx.replace(/%/g, "(.|\n)*"); // Repalce sql any-length-wildcard with regex any-length-wildcard
            vRegEx = vRegEx.replace(/_/g, "."); // Replace sql single-char-wildcard with regex single-char-wildcard
            vRegEx = vRegEx.replace(/\[(.|\n)*\]/g, "%"); // Find any sql escaped ('[%]') any-length-wildcards, and replace with just the char
            vRegEx = vRegEx.replace(/\[.\]/g, "_"); // Find any sql escaped ('[_]') single-char-wildcards, and replace with just the char
            vRegEx = vRegEx.replace(/\[\[\]/g, "\\["); // Find any escaped start-brackets and un-escape them one level (so they are still regex escaped)
            return new RegExp(vRegEx).test(pFieldValue); // Create the RegExp object and test it against pTestingValue
        },
        isblank: function (pFieldValue: string) { return pFieldValue === null || pFieldValue === ""; },
        notequals: function (pFieldValue: any, pTestingValue: any) { return pFieldValue !== pTestingValue; },
        greaterthanorequal: function (pFieldValue: number, pTestingValue: number) { return pFieldValue >= pTestingValue; },
        lessthanorequal: function (pFieldValue: number, pTestingValue: number) { return pFieldValue <= pTestingValue; },
        notbeginswith: function (pFieldValue: any, pTestingValue: any) { return !operatorEvaluators.beginswith(pFieldValue, pTestingValue); },
        notendswith: function (pFieldValue: any, pTestingValue: any) { return !operatorEvaluators.endswith(pFieldValue, pTestingValue); },
        notcontains: function (pFieldValue: string, pTestingValue: string) { return !operatorEvaluators.contains(pFieldValue, pTestingValue); },
        isnotnull: function (pFieldValue: any) { return pFieldValue !== null; },
        isfalse: function (pFieldValue: boolean) { return pFieldValue === false; },
        notinlist: function (pFieldValue: any, pTestingValues: any) { return !operatorEvaluators.inlist(pFieldValue, pTestingValues); },
        notbetween: function (pFieldValue: number, pTestingValues: number) { return !operatorEvaluators.between(pFieldValue, pTestingValues); },
        dateequals: function (pFieldValue: Date, pTestingValue: Date) {
            if (!pFieldValue && pTestingValue) return false;
            if (typeof pFieldValue == 'string') {
                pFieldValue = new Date(pFieldValue);
            }
            if (typeof pTestingValue == 'string') {
                pTestingValue = new Date(pTestingValue);
            }
            if (pFieldValue === null || pTestingValue === null) { return pFieldValue === pTestingValue; }

            return (pFieldValue.getFullYear() === pTestingValue.getFullYear() &&
                pFieldValue.getMonth() === pTestingValue.getMonth() &&
                pFieldValue.getDate() === pTestingValue.getDate());
        },
        datebetween: function (pFieldValue: Date, pTestingValues: Array<Date>) {
            if (typeof pFieldValue == 'string') {
                pFieldValue = new Date(pFieldValue);
            }

            if (pTestingValues instanceof Array) {
                return (pFieldValue >= pTestingValues[0] &&
                    pFieldValue <= pTestingValues[1]);
            } else {
                return false;
            }
        },
        notdatequals: function (pFieldValue: any, pTestingValue: any) {
            if (pTestingValue) {
                pTestingValue = new Date(pTestingValue);
            }
            if (typeof pTestingValue == 'string') {
                pTestingValue = new Date(pTestingValue);
            }
            if (pFieldValue === null || pTestingValue === null) { return pFieldValue === pTestingValue; }
            return !(pFieldValue.getFullYear() === pTestingValue.getFullYear() &&
                pFieldValue.getMonth() === pTestingValue.getMonth() &&
                pFieldValue.getDate() === pTestingValue.getDate());
        },
        datenotbetween: function (pFieldValue: any, pTestingValue: any) {
            if (pTestingValue) {
                pTestingValue = new Date(pTestingValue);
            }
            if (pFieldValue === null || pTestingValue === null) { return pFieldValue === pTestingValue; }
            return (pFieldValue < pTestingValue[0] || pFieldValue > pTestingValue[1]);
        },


        dategreaterthan: function (pFieldValue: any, pTestingValue: any) {
            if (pTestingValue) {
                pTestingValue = new Date(pTestingValue);
            }
            if (typeof pFieldValue == 'string') {
                pFieldValue = new Date(pFieldValue);
            }
            return pFieldValue > pTestingValue;
        },
        dategreaterthanorequal: function (pFieldValue: any, pTestingValue: any) {
            if (pTestingValue) {
                pTestingValue = new Date(pTestingValue);
            }
            if (typeof pFieldValue == 'string') {
                pFieldValue = new Date(pFieldValue);
            }
            return pFieldValue >= pTestingValue;
        },
        datelessthan: function (pFieldValue: any, pTestingValue: any) {
            if (pTestingValue) {
                pTestingValue = new Date(pTestingValue);
            }
            if (typeof pFieldValue == 'string') {
                pFieldValue = new Date(pFieldValue);
            }
            return pFieldValue < pTestingValue;
        },
        datelessthanorequal: function (pFieldValue: any, pTestingValue: any) {
            if (pTestingValue) {
                pTestingValue = new Date(pTestingValue);
            }
            if (typeof pFieldValue == 'string') {
                pFieldValue = new Date(pFieldValue);
            }
            return pFieldValue <= pTestingValue;
        },

        timebetween: function (pFieldValue: Date, pTestingValues: Date) {
            if (pTestingValues instanceof Array) {
                var vFieldTime = getTimeInSeconds(pFieldValue);
                return (vFieldTime >= getTimeInSeconds(pTestingValues[0]) &&
                    vFieldTime <= getTimeInSeconds(pTestingValues[1]));
            } else {
                return false;
            }
        },
        timeequals: function (pFieldValue: Date, pTestingValue: Date) {
            return (pFieldValue.getHours() === pTestingValue.getHours() &&
                pFieldValue.getMinutes() === pTestingValue.getMinutes() &&
                pFieldValue.getSeconds() === pTestingValue.getSeconds());
        },
        timebefore: function (pFieldValue: Date, pTestingValue: Date) {
            return getTimeInSeconds(pFieldValue) < getTimeInSeconds(pTestingValue);
        },
        timeafter: function (pFieldValue: Date, pTestingValue: Date) {
            return getTimeInSeconds(pFieldValue) > getTimeInSeconds(pTestingValue);
        },
        notlike: function (pFieldValue: string, pTestingValue: string) { return !operatorEvaluators.like(pFieldValue, pTestingValue); },
        isnotblank: function (pFieldValue: any) { return pFieldValue !== null && pFieldValue !== ""; }
    };

    const operatorSqlifiers = {
        equals: function (pColumn: any, pValue: any) {
            return safeWrapName(pColumn) + " = " + safeWrapValue(pValue);
        },
        greaterthan: function (pColumn: string, pValue: number) { return safeWrapName(pColumn) + " > " + safeWrapValue(pValue); },
        lessthan: function (pColumn: string, pValue: number) { return safeWrapName(pColumn) + " < " + safeWrapValue(pValue); },
        beginswith: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? "StartsWith(" + safeWrapName(pColumn) + "," + safeEscapeValue(pValue) + ")" : safeWrapName(pColumn) + " LIKE '" + safeEscapeValue(pValue) + "%'"; }, // TODO: Stop using LIKE
        endswith: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? "EndsWith(" + safeWrapName(pColumn) + "," + safeEscapeValue(pValue) + ")" : safeWrapName(pColumn) + " LIKE '%" + safeEscapeValue(pValue) + "'"; }, // TODO: Stop using LIKE
        contains: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? "Contains(" + safeWrapName(pColumn) + "," + safeEscapeValue(pValue) + ")" : safeWrapName(pColumn) + " LIKE '%" + safeEscapeValue(pValue) + "%'"; }, // TODO: Stop using LIKE
        isnull: function (pColumn: string) {
            return pColumn + " IS NULL";
            // return "ISNULL(" + safeWrapName(pColumn)+ ",'') = ''"; 
        },
        istrue: function (pColumn: string) { return safeWrapName(pColumn) + " = 1"; },
        inlist: function (pColumn: string, pValues: any) {
            if (isBlankValueUsed(pValues)) {
                return "ISNULL(" + safeWrapName(pColumn) + ",'')" + " IN (" + safeEscapeValueList(pValues, ",") + ")";
            } else {
                return safeWrapName(pColumn) + " IN (" + safeEscapeValueList(pValues, ",") + ")";
            }

        },
        between: function (pColumn: string, pValues: any) {
            if (pValues instanceof Array) {
                pValues.length = 2; // Make sure we only and always have two values
                if (pValues[0] === undefined) { pValues[0] = null; }
                if (pValues[1] === undefined) { pValues[1] = null; }
            } else if (pValues !== undefined) {
                pValues = [pValues, null];
            } else {
                pValues = [null, null];
            }
            return "(" + safeWrapName(pColumn) + " BETWEEN (" + safeEscapeValueList(pValues, ", ") + "))";
        },

        like: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? safeWrapName(pColumn) + " LIKE " + safeEscapeValue(pValue) : safeWrapName(pColumn) + " LIKE '" + safeEscapeValue(pValue) + "'"; },
        isblank: function (pColumn: string) { return "ISNULL(" + safeWrapName(pColumn) + ", '') = ''"; },
        notequals: function (pColumn: string, pValue: any) { return "ISNULL(" + safeWrapName(pColumn) + ", '')" + " <> " + safeWrapValue(pValue); },
        greaterthanorequal: function (pColumn: string, pValue: number) { return safeWrapName(pColumn) + " >= " + safeWrapValue(pValue); },
        lessthanorequal: function (pColumn: string, pValue: number) { return safeWrapName(pColumn) + " <= " + safeWrapValue(pValue); },
        notbeginswith: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? "NOT StartsWith(" + safeWrapName(pColumn) + "," + safeEscapeValue(pValue) + ")" : safeWrapName(pColumn) + " NOT LIKE '" + safeEscapeValue(pValue) + "%'"; }, // TODO: Stop using LIKE
        notendswith: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? "NOT EndsWith(" + safeWrapName(pColumn) + "," + safeEscapeValue(pValue) + ")" : safeWrapName(pColumn) + " NOT LIKE '%" + safeEscapeValue(pValue) + "'"; }, // TODO: Stop using LIKE
        notcontains: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? "NOT Contains(" + safeWrapName(pColumn) + "," + safeEscapeValue(pValue) + ")" : safeWrapName(pColumn) + " NOT LIKE '%" + safeEscapeValue(pValue) + "%'"; }, // TODO: Stop using LIKE
        isnotnull: function (pColumn: string) { return safeWrapName(pColumn) + " IS NOT NULL"; },
        isfalse: function (pColumn: string) { return safeWrapName(pColumn) + " = 0"; },
        notinlist: function (pColumn: string, pValues: Array<any>) { return "(NOT " + (isBlankValueUsed(pValues) ? "ISNULL(" + safeWrapName(pColumn) + ",'')" : safeWrapName(pColumn) + " IN (" + safeEscapeValueList(pValues, ",")) + "))"; },
        notbetween: function (pColumn: string, pValues: Array<any>) {
            pValues.length = 2; // Make sure we only and always have two values
            if (pValues[0] === undefined) { pValues[0] = null; }
            if (pValues[1] === undefined) { pValues[1] = null; }
            return "(NOT " + safeWrapName(pColumn) + " BETWEEN (" + safeEscapeValueList(pValues, ", ") + "))";
        },

        dateequals: function (pColumn: string, pValue: Date) { return "DateDiffDay(" + safeWrapName(pColumn) + ", " + safeWrapValue(pValue) + ") = 0"; },
        notdatequals: function (pColumn: string, pValue: Date) { return "DateDiffDay(" + safeWrapName(pColumn) + ", " + safeWrapValue(pValue) + ") <> 0"; },
        datebetween: function (pColumn: string, pValue: Array<Date>) { return "(" + safeWrapName(pColumn) + " BETWEEN (" + safeEscapeValueList(pValue, ", ") + "))"; },
        datenotbetween: function (pColumn: string, pValue: Array<Date>) { return " NOT (" + safeWrapName(pColumn) + " BETWEEN (" + safeEscapeValueList(pValue, ", ") + "))"; },
        dategreaterthan: function (pColumn: string, pValue: Date) { return safeWrapName(pColumn) + " > " + safeWrapValue(pValue) + ""; },
        dategreaterthanorequal: function (pColumn: string, pValue: Date) { return safeWrapName(pColumn) + " >= " + safeWrapValue(pValue) + ""; },
        datelessthan: function (pColumn: string, pValue: Date) { return safeWrapName(pColumn) + " < " + safeWrapValue(pValue) + ""; },
        datelessthanorequal: function (pColumn: string, pValue: Date) { return safeWrapName(pColumn) + " <= " + safeWrapValue(pValue) + ""; },

        timebetween: function (pColumn: string, pValues: Array<Date | null>) {
            pValues.length = 2; // Make sure we only and always have two values;
            if (pValues[0] === undefined) { pValues[0] = null; }
            if (pValues[1] === undefined) { pValues[1] = null; }
            return "(" + safeWrapNameAndConvertToTime(pColumn) + " BETWEEN " + pValues.map(safeWrapValueAndConvertToTime).join(" AND ") + ")";
        },
        timeequals: function (pColumn: string, pValue: Date) { return convertToTime(safeWrapName(pColumn)) + " = " + convertToTime(safeWrapValue(pValue)); },
        timebefore: function (pColumn: string, pValue: Date) { return convertToTime(safeWrapName(pColumn)) + " < " + convertToTime(safeWrapValue(pValue)); },
        timeafter: function (pColumn: string, pValue: Date) { return convertToTime(safeWrapName(pColumn)) + " > " + convertToTime(safeWrapValue(pValue)); },
        notlike: function (pColumn: string, pValue: string) { return checkIfColumn(pValue) ? safeWrapName(pColumn) + " NOT LIKE " + safeEscapeValue(pValue) : safeWrapName(pColumn) + " NOT LIKE '" + safeEscapeValue(pValue) + "'"; },
        isnotblank: function (pColumn: string) { return "ISNULL(" + safeWrapName(pColumn) + ", '') <> ''"; }
    };

    const operatorPrettifiers = {
        equals: function (pColumn: string, pValue: any) { return `[${pColumn}] = '${pValue}'`; },
        greaterthan: function (pColumn: string, pValue: number) { return `[${pColumn}] > ${pValue}`; },
        lessthan: function (pColumn: string, pValue: number) { return `[${pColumn}] < ${pValue}`; },
        beginswith: function (pColumn: string, pValue: string) { return `[${pColumn}] ${operatorTitles['beginswith']} '${pValue}'`; },
        endswith: function (pColumn: string, pValue: string) { return `[${pColumn}] ${operatorTitles['endswith']} '${pValue}'`; },
        contains: function (pColumn: string, pValue: string) { return `[${pColumn}] ${operatorTitles['contains']} '${pValue}'`; },
        isnull: function (pColumn: string) { return `[${pColumn}] ${operatorTitles['isnull']}`; },
        istrue: function (pColumn: string) { return `[${pColumn}] ${operatorTitles['istrue']}`; },
        inlist: function (pColumn: string, pValues: Array<any>) { return `[${pColumn}] ${operatorTitles['inlist']}(${safeEscapeValueList(pValues, ", ")})`; },
        between: function (pColumn: string, pValues: Array<Date | null>) {
            if (pValues instanceof Array) {
                pValues.length = 2; // Make sure we only and always have two values
                if (pValues[0] === undefined) { pValues[0] = null; }
                if (pValues[1] === undefined) { pValues[1] = null; }
            } else if (pValues !== undefined) {
                pValues = [pValues, null];
            } else {
                pValues = [null, null];
            }
            return (`[${pColumn}] ${operatorTitles['between']}(${safeEscapeValueList(pValues, " - ")})`).replace(/''/g, '');
        },

        isblank: function (pColumn: string) { return `[${pColumn}] ${operatorTitles['isblank']}`; },
        notequals: function (pColumn: string, pValue: any) { return `[${pColumn}] <> '${pValue}'`; },
        greaterthanorequal: function (pColumn: string, pValue: number) { return `[${pColumn}] >= ${pValue}`; },
        lessthanorequal: function (pColumn: string, pValue: number) { return `[${pColumn}] <= ${pValue}`; },
        notbeginswith: function (pColumn: string, pValue: string) { return `[${pColumn}] ${operatorTitles['notbeginswith']} '${pValue}'`; },
        notendswith: function (pColumn: string, pValue: string) { return `[${pColumn}] ${operatorTitles['notendswith']} '${pValue}'`; },
        notcontains: function (pColumn: string, pValue: string) { return `[${pColumn}] ${operatorTitles['notcontains']} '${pValue}'`; },
        isnotnull: function (pColumn: string) { return `[${pColumn}] ${operatorTitles['isnotnull']}`; },
        isfalse: function (pColumn: string) { return `[${pColumn}] ${operatorTitles['isfalse']}`; },
        notinlist: function (pColumn: string, pValues: Array<any>) { return `[${pColumn}] ${operatorTitles['notinlist']}(${safeEscapeValueList(pValues, ", ")})`; },
        notbetween: function (pColumn: string, pValues: Array<any>) {
            pValues.length = 2; // Make sure we only and always have two values
            if (pValues[0] === undefined) { pValues[0] = null; }
            if (pValues[1] === undefined) { pValues[1] = null; }
            return `[${pColumn}]  ${operatorTitles['notbetween']}(${safeEscapeValueList(pValues, " - ")})`;
        },

        dateequals: function (pColumn: string, pValue: Date) { return `[${pColumn}] = '${safeWrapValue(pValue)}'`; },
        notdatequals: function (pColumn: string, pValue: Date) { return `[${pColumn}] <> '${safeWrapValue(pValue)}'`; },
        datebetween: function (pColumn: string, pValue: Array<Date>) { return `[${pColumn}]  ${operatorTitles['between']}(${safeEscapeValueList(pValue, " - ")})`; },
        datenotbetween: function (pColumn: string, pValue: Array<Date>) { return `[${pColumn}]  ${operatorTitles['notbetween']}(${safeEscapeValueList(pValue, " - ")})`; },
        dategreaterthan: function (pColumn: string, pValue: Date) { return `[${pColumn}] > '${safeWrapValue(pValue)}'`; },
        dategreaterthanorequal: function (pColumn: string, pValue: Date) { return `[${pColumn}] >= '${safeWrapValue(pValue)}'`; },
        datelessthan: function (pColumn: string, pValue: Date) { return `[${pColumn}] < '${safeWrapValue(pValue)}'`; },
        datelessthanorequal: function (pColumn: string, pValue: Date) { return `[${pColumn}] <= '${safeWrapValue(pValue)}'`; },

        /*timebetween: function(pColumn, pValues) {
            pValues.length = 2; // Make sure we only and always have two values;
            if (pValues[0] === undef) { pValues[0] = null; }
            if (pValues[1] === undef) { pValues[1] = null; }
            return "(" + safeWrapNameAndConvertToTime(pColumn) + " BETWEEN " + pValues.map(safeWrapValueAndConvertToTime).join(" AND ") + ")";
        },
        timeequals: function(pColumn, pValue) { return convertToTime(safeWrapName(pColumn)) + " = " + convertToTime(safeWrapValue(pValue)); },
        timebefore: function(pColumn, pValue) { return convertToTime(safeWrapName(pColumn)) + " < " + convertToTime(safeWrapValue(pValue)); },
        timeafter: function(pColumn, pValue) { return convertToTime(safeWrapName(pColumn)) + " > " + convertToTime(safeWrapValue(pValue)); },*/
        //notlike: function(pColumn, pValue) { return checkIfColumn(pValue)?safeWrapName(pColumn) + " NOT LIKE " + safeEscapeValue(pValue):safeWrapName(pColumn) + " NOT LIKE '" + safeEscapeValue(pValue) + "'"; },
        isnotblank: function (pColumn: string) { return `[${pColumn}] ${operatorTitles['isnotblank']}`; },
    };

    function isBlankValueUsed(pValues: string | any[]) {
        if (pValues instanceof Array && pValues.length) {
            for (var i = 0; i < pValues.length; i++) {
                if (pValues[i] === null) {
                    return true;
                }
                if (pValues[i].length === 0) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    function getTimeInSeconds(pDate: Date) {
        return (pDate.getHours() * 60 + pDate.getMinutes()) * 60 + pDate.getSeconds();
    }

    function safeWrapName(pName: any) {
        return pName.replace(regexes.beginBracket, "[[");
    }

    function safeEscapeValue(pValue: any) {
        if (pValue === null) { return ""; }
        if (typeOf(pValue) === "datetime") { return "#" + formatDate(pValue, "yyyy-MM-dd", false, false) + "#"; }
        return pValue.toString().replace(regexes.apostrophe, "''");
    }

    function safeWrapValue(pValue: any) {
        if (typeOf(pValue) === "datetime") {
            return (pValue === null ? "NULL" : safeEscapeValue(pValue));
        } else {
            if (typeof pValue === "number") {
                return (pValue === null ? "NULL" : "" + safeEscapeValue(pValue) + "");
            } else if (isDateExpression(pValue)) {
                return pValue;
            } else if (checkIfColumn(pValue)) {
                return (pValue === null ? "NULL" : safeEscapeValue(pValue));
            } else {
                return (pValue === null ? "NULL" : "'" + safeEscapeValue(pValue) + "'");
            }

        }
    }

    function convertToTime(pValue: any) {
        return "CONVERT(time, " + pValue + ")";
    }

    function safeWrapNameAndConvertToTime(pValue: any) {
        return convertToTime(safeWrapName(pValue));
    }

    function safeWrapValueAndConvertToTime(pValue: any) {
        return convertToTime(safeWrapValue(pValue));
    }

    function safeEscapeValueList(pValues: Array<any>, pSeparator: string) {
        return (pValues instanceof Array && pValues.length ? pValues.map(safeWrapValue).join(pSeparator) : "NULL");
    }

    function typeOf(pValue: any) {
        if (pValue === null) {
            return "null";
        } else if (typeof pValue === "object") {
            if (pValue instanceof Array) { return "array"; }
            else if (pValue instanceof Date) { return "datetime"; }
            else if (pValue instanceof Number) { return "number"; }
        }
        return (typeof pValue);
    }

    function returnFirstArgument(arg0: any) {
        return arg0;
    }

    function evaluateExpression(pFilterExpression: FilterItem, pItem: FilterItem) {
        let vFieldValue: any,
            vTestingValue: any,
            vColumnName = pFilterExpression.column;
        if (typeOf(vColumnName) === "object") {
            vColumnName = vColumnName['Name'] || vColumnName['name'] || vColumnName;
        }
        if (vColumnName === "1" && pFilterExpression.value === "1") {
            return true;
        }

        if (Object.prototype.hasOwnProperty.call(pItem, vColumnName)) {
            if (operatorEvaluators.hasOwnProperty(pFilterExpression.operator)) {
                vFieldValue = pItem[vColumnName];
                vTestingValue = pFilterExpression.value;
                if (typeOf(vFieldValue) === "string") { vFieldValue = fixValue(pFilterExpression, vFieldValue) }
                vTestingValue = fixValue(pFilterExpression, vTestingValue);
                if (vTestingValue instanceof Array) {
                    vTestingValue = vTestingValue.map(function (val) {
                        return fixValue(pFilterExpression, val);
                    });
                }
                return operatorEvaluators[pFilterExpression.operator].call(pFilterExpression, vFieldValue, vTestingValue);
            } else {
                throw new Error("Invalid filter expression - no operator evaluator found for operator '" + pFilterExpression.operator + "'.");
            }
        } else {
            throw new Error("Invalid filter expression - column '" + vColumnName + "' does not exist.");
        }
    }

    function fixValue(pFilterExpression: FilterItem, pTestingValue: any) {
        if (typeOf(pTestingValue) === "string") {
            if ((pFilterExpression.valueType === "date" || pFilterExpression.valueType === "datetime") && dateExpressionValues.hasOwnProperty(pTestingValue)) {
                pTestingValue = dateExpressionValues[pTestingValue];
            } else if ((pFilterExpression.valueType === "date" || pFilterExpression.valueType === "datetime")) {
                pTestingValue = new Date(pTestingValue);
            } else {
                pTestingValue = pTestingValue.toLowerCase();
            }
        }
        return pTestingValue;
    }

    function evaluateGroup(pFilterGroup: FilterObject, pItem: FilterItem) {
        if (pFilterGroup.mode === "or") {
            for (let i = 0; i < pFilterGroup.items.length; i++) {
                if (evaluate(pFilterGroup.items[i], pItem)) { return true; }
            }
            return false;
        } else if (pFilterGroup.mode === "and") {
            for (let i = 0; i < pFilterGroup.items.length; i++) {
                if (!evaluate(pFilterGroup.items[i], pItem)) { return false; }
            }
            return true; // If none of the items failed, return success
        } else {
            throw new Error("Invalid filter group - mode can only be 'or' or 'and'.");
        }
    }

    function evaluate(pFilter: any, pItem: FilterItem) {
        if (pFilter.type === "group") {
            return evaluateGroup(pFilter, pItem);
        } else if (pFilter.type === "expression") {
            return evaluateExpression(pFilter, pItem);
        } else {
            throw new Error("Invalid filter - type can only be 'group' or 'expression'.");
        }
    }

    /* Filter SQLify
    */
    function sqlifyExpression(pFilterExpression: FilterItem) {
        var vColumnName: string, vOperator = pFilterExpression.operator;
        if (typeOf(vOperator) === "object") {
            vOperator = vOperator['operator'];
        }
        if (vOperator) {
            if (operatorSqlifiers.hasOwnProperty(vOperator)) {
                vColumnName = pFilterExpression.column;
                if (typeOf(vColumnName) === "object") {
                    vColumnName = vColumnName['Name'] || vColumnName['name'] || vColumnName;
                }
                if (!vColumnName) {
                    self.o365.logger.warn("Failed to parse column");
                    return null;
                }
                return operatorSqlifiers[vOperator].call(pFilterExpression, vColumnName, fixValue(pFilterExpression, pFilterExpression.value));
            } else {
                throw new Error("Invalid filter expression - no operator evaluator found for operator '" + vOperator + "'.");
            }
        } else {
            return "/* missing operator */";
        }
    }

    function sqlifyGroup(pFilterGroup: FilterGroup) {
        var vResult: any;
        if (pFilterGroup.mode === "or" || pFilterGroup.mode === "and") {
            // Turn all items into sql strings, and remove any empty strings
            vResult = pFilterGroup.items.map(sqlify).filter(returnFirstArgument);
            if (vResult.length > 1) {
                return "(" + vResult.join(" " + pFilterGroup.mode.toUpperCase() + " ") + ")";
            } else {
                return vResult[0] || "";
            }
        } else {
            throw new Error("Invalid filter group - mode can only be 'or' or 'and'.");
        }
    }

    function sqlify(pFilter: any) {
        if (pFilter.type === "group") {
            return sqlifyGroup(pFilter);
        } else if (pFilter.type === "expression") {
            return sqlifyExpression(pFilter);
        } else {
            throw new Error("Invalid filter - type can only be 'group' or 'expression'.");
        }
    }

    function prettifyGroup(pFilterGroup: FilterGroup, pColumns: Array<any>) {
        var vResult: any;
        if (pFilterGroup.mode === "or" || pFilterGroup.mode === "and") {
            // Turn all items into sql strings, and remove any empty strings
            vResult = pFilterGroup.items.map(x => toPrettyString(x, pColumns)).filter(returnFirstArgument);
            if (vResult.length > 1) {

                return "(" + vResult.join(" " + pFilterGroup.mode.toUpperCase() + " ") + ")";
            } else {
                return vResult[0] || "";
            }
        } else {
            self.o365.logger.warn("Invalid filter group - mode can only be 'or' or 'and'.");
        }
    }

    function toPrettyString(pFilter: any, pColumns: Array<any>) {

        if (pFilter.type === "group") {
            return prettifyGroup(pFilter, pColumns);
        } else if (pFilter.type === "expression") {
            return prettifyExpression(pFilter, pColumns.find(col => col.name == pFilter.column));
        } else {
            throw new Error("Invalid filter - type can only be 'group' or 'expression'.");
        }
    }

    function prettifyExpression(pFilterExpression: FilterItem, pColumn: any) {

        var vColumnName: string, vOperator = pFilterExpression['operator'];
        if (typeOf(vOperator) === "object") {
            vOperator = vOperator['operator'];
        }
        if (vOperator) {
            if (pFilterExpression && pFilterExpression.hasOwnProperty('dateExpression') && pFilterExpression['dateExpression']) {
                vColumnName = (pColumn && pColumn['caption']) ?? pFilterExpression['column'];
                return `[${vColumnName}] ${pFilterExpression['dateExpression']['title']}`
            } else if (operatorPrettifiers.hasOwnProperty(vOperator)) {
                vColumnName = (pColumn && pColumn['caption']) ?? pFilterExpression['column'];
                if (typeOf(vColumnName) === "object") {
                    vColumnName = vColumnName['Name'] || vColumnName['name'] || vColumnName;
                }
                return operatorPrettifiers[vOperator].call(pFilterExpression, vColumnName, fixValue(pFilterExpression, pFilterExpression['value']));
            } else {
                throw new Error("Invalid filter expression - no operator evaluator found for operator '" + vOperator + "'.");
            }
        } else {
            return "/* missing operator */";
        }
    }

    function checkIfColumn(value: string) {
        if (value === null || value === undefined) {
            return false;
        }
        if (typeof value === "string" && value.indexOf("[") === 0 && value.indexOf("]") === value.length - 1) {
            return true;
        } else {
            return false;
        }
    }

    self.o365.exportScripts({ Devex });
})();

